import React from 'react';

import './ImageBoxes.scss';

const ImageBoxes = (props) => (
  <div className="image-boxes">
    {props.children}
  </div>
);

export default ImageBoxes;
