import React from 'react';

import PropTypes from 'prop-types';
import {FormattedHTMLMessage} from "react-intl";

const ScopeItem = ({children, title}) => (
  <div className="scope-item">
    <div className="scope-item__icon">
      {children}
    </div>
    <div className="scope-item__title">
      <FormattedHTMLMessage id={title} />
    </div>
  </div>
);

ScopeItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  title: PropTypes.string.isRequired
};

export default ScopeItem;
