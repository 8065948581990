import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import Heading from "../../Typography/Heading";
import Section from "../../layout/Sections/Section";

const Bgc = styled.section`
  ${props => props.bgcStyle}
`;

const HeadingStyle = styled.div`
  ${props => props.headingStyle}
`;

const Projects = ({title, titleTag, bgcStyle, headingStyle, headingType, children}) => (
  <Bgc className="background-helper" bgcStyle={bgcStyle}>
    <Section sectionTag="div" modifiers={['padding-x', 'padding-y-top']}>
      <HeadingStyle headingStyle={headingStyle} type={headingType}>
        <Heading title={title} size="medium-2x" modifiers="heading--white" titleTag={titleTag} />
      </HeadingStyle>
    </Section>
    <Section sectionTag="div" modifiers={['projects', 'columns', 'padding-y-bottom']} modifiersLayout={['top']} modifiersWrapper={['wider']}>
      {children}
    </Section>
  </Bgc>
);

Projects.propTypes = {
  title: PropTypes.string.isRequired
};

export default Projects;
