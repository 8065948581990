import React from 'react';
import PropTypes from 'prop-types';

import './ImageBox.scss';
import {FormattedHTMLMessage} from "react-intl";

const ImageBox = (props) => (
  <div className="image-box">
    {props.children}
    {(props.title || props.text) &&
      <div className="image-box__content">
        <div className="image-box__title"><FormattedHTMLMessage id={props.title} /></div>
        <div className="image-box__text"><FormattedHTMLMessage id={props.text} /></div>
      </div>
    }
  </div>
);

ImageBox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  title: PropTypes.string,
  text: PropTypes.string
};

export default ImageBox;
