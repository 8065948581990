import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import Heading from "../../Typography/Heading";

const HeadingStyle = styled.div`
  ${props => props.headingStyle}
`;

const Realizations = ({title, titleTag, headingStyle, headingType, children}) => (
  <>
    <section className="section section--realizations section--padding-x section--padding-y-equal">
      <div className="wrapper">
        <HeadingStyle headingStyle={headingStyle} type={headingType}>
          <Heading title={title} size="medium-2x" modifiers="heading--center" titleTag={titleTag} />
        </HeadingStyle>
      </div>
    </section>
    {children}
  </>
);

Realizations.propTypes = {
  // background: PropTypes.object.isRequired,
  // image: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
};

export default Realizations;
