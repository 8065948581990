import React from 'react';
import PropTypes from 'prop-types';
import Img from "gatsby-image";
import {FormattedMessage} from "react-intl";
import styled from "styled-components";
import Section from "../../layout/Sections/Section";
import SectionColumn from "../../layout/Sections/SectionColumn";
import TextContent from "../../Typography/TextContent";

const LogoStyle = styled.div`
  ${props => props.logoStyle}
`;

const Scope = ({image, title, children, logoStyle}) => (
  <Section modifiers={['scope', 'columns', 'padding-x', 'padding-y-equal']} modifiersLayout={['padding']}>
    <SectionColumn>
      <LogoStyle logoStyle={logoStyle}>
        <Img className='image' fluid={image} />
      </LogoStyle>
    </SectionColumn>
    <SectionColumn>
      <TextContent modifiers={['section']}>
        <FormattedMessage id={title} />
      </TextContent>
      {children}
    </SectionColumn>
  </Section>
);

Scope.propTypes = {
  image: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
};

export default Scope;
