import React from 'react';
import PropTypes from 'prop-types';
import Img from "gatsby-image";

import Heading from "../../Typography/Heading";
import TextContent from "../../Typography/TextContent";
import {FormattedHTMLMessage} from "react-intl";
import Section from "../../layout/Sections/Section";
import SectionColumn from "../../layout/Sections/SectionColumn";

const Info = ({image, title1, title2, text1, text2, children}) => (
  <section className="background-helper">
    <div className="background-full">
      <Img className='image' fluid={image} />
    </div>
    <Section sectionTag="div" modifiers={['info', 'columns', 'padding-x', 'padding-y-equal']} modifiersLayout={['top']}>
      <SectionColumn>
        <Heading title={title1} size="section" modifiers="heading--white" />
        <TextContent modifiers={['small', 'white']}>
          <FormattedHTMLMessage id={text1} />
        </TextContent>
        <div className="buttons-inline">
          {children}
        </div>
      </SectionColumn>
      <SectionColumn>
        <Heading title={title2} size="small-2x" modifiers="heading--white" />
        <TextContent modifiers={['small', 'white']}>
          <FormattedHTMLMessage id={text2} />
        </TextContent>
      </SectionColumn>
    </Section>
  </section>
);

Info.propTypes = {
  image: PropTypes.object.isRequired,
  title1: PropTypes.string.isRequired,
  title2: PropTypes.string.isRequired,
  text1: PropTypes.string.isRequired,
  text2: PropTypes.string.isRequired
};

export default Info;
