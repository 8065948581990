import React from 'react';
import PropTypes from 'prop-types';
import Img from "gatsby-image";

const Movie = ({image}) => (
  <section className="section--movie">
    <Img className='image' fluid={image} />
  </section>
);

Movie.propTypes = {
  image: PropTypes.object.isRequired
};

export default Movie;
