import React, {Component} from 'react';
import withLayout from '../../layout';
import styled, {css} from 'styled-components';

import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import "./Layout.scss";
import ContactSlide from "../../components/Contact/ContactSlide";

import {injectIntl} from "react-intl";
import BardziejButton from '../../components/BardziejButton';
import ImageBoxes from '../../components/layout/Images/ImageBoxes';
import ImageBox from '../../components/layout/Images/ImageBox';
import ScopeItem from '../../components/projects/ScopeItem';
import Banner from '../../components/projects/sections/Banner';
import Scope from '../../components/projects/sections/Scope';
import Info from '../../components/projects/sections/Info';
import Projects from '../../components/projects/sections/Projects';
import Movie from '../../components/projects/sections/Movie';
import Desc from '../../components/projects/sections/Desc';
import Realizations from '../../components/projects/sections/Realizations';

import Icon1 from '../../shared/icons/projects/icon1.svg';
import Icon3 from '../../shared/icons/projects/icon3.svg';
import Icon5 from '../../shared/icons/projects/icon5.svg';
import Icon8 from '../../shared/icons/projects/icon8.svg';
import Icon9 from '../../shared/icons/projects/icon9.svg';
import Icon10 from '../../shared/icons/projects/icon10.svg';

class BlackProject extends Component {

  componentDidMount() {
    document.body.classList.add('painted');
  }

  render() {
    const logoStyle = css`
      max-width: 169px;
    `;

    const IconStyle = styled.div`
      svg path {
        fill: #ed1b2e;
      }
    `;

    const bgcStyle = css`
      background-color: #101010;
    `;

    const headingStyle = css`
      .text {
        color: ${props => props.type === "1" ? "#574631" : 
                          props.type === "2" ? "#ffffff" : 
                          props.type === "3" ? "#0c1d27" :
                          props.type === "4" ? "#d2d2d2" : '#ffffff'};
      }
    `;

    return (
      <>
        <StaticQuery
          query={graphql`
            query {
              top: file(relativePath: { eq: "projekty/black/top.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100, srcSetBreakpoints: [320, 360, 620, 640, 768, 1024, 1200, 1366, 1920]) {
                    ...GatsbyImageSharpFluid
                  }
                }
              },
              logo: file(relativePath: { eq: "projekty/black/logo.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100, srcSetBreakpoints: [320, 360, 620, 640, 768, 1024, 1200, 1366, 1920]) {
                    ...GatsbyImageSharpFluid
                  }
                }
              },
              infobg: file(relativePath: { eq: "projekty/black/bg.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100, srcSetBreakpoints: [320, 360, 620, 640, 768, 1024, 1200, 1366, 1920]) {
                    ...GatsbyImageSharpFluid
                  }
                }
              },
              makietybg: file(relativePath: { eq: "projekty/black/makiety-bg.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100, srcSetBreakpoints: [320, 360, 620, 640, 768, 1024, 1200, 1366, 1920]) {
                    ...GatsbyImageSharpFluid
                  }
                }
              },
              makietyobject: file(relativePath: { eq: "projekty/black/makiety-object.png" }) {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100, srcSetBreakpoints: [320, 360, 620, 640, 768, 1024, 1200, 1366, 1920]) {
                    ...GatsbyImageSharpFluid
                  }
                }
              },
              g1: file(relativePath: { eq: "projekty/black/g1.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100, srcSetBreakpoints: [320, 360, 620, 640, 768, 1024, 1200, 1366, 1920]) {
                    ...GatsbyImageSharpFluid
                  }
                }
              },
              g2: file(relativePath: { eq: "projekty/black/g2.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100, srcSetBreakpoints: [320, 360, 620, 640, 768, 1024, 1200, 1366, 1920]) {
                    ...GatsbyImageSharpFluid
                  }
                }
              },
              g3: file(relativePath: { eq: "projekty/black/g3.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100, srcSetBreakpoints: [320, 360, 620, 640, 768, 1024, 1200, 1366, 1920]) {
                    ...GatsbyImageSharpFluid
                  }
                }
              },
              g4: file(relativePath: { eq: "projekty/black/g4.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100, srcSetBreakpoints: [320, 360, 620, 640, 768, 1024, 1200, 1366, 1920]) {
                    ...GatsbyImageSharpFluid
                  }
                }
              },
              moviebg: file(relativePath: { eq: "projekty/black/movie-bg.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100, srcSetBreakpoints: [320, 360, 620, 640, 768, 1024, 1200, 1366, 1920]) {
                    ...GatsbyImageSharpFluid
                  }
                }
              },
              boxleft: file(relativePath: { eq: "projekty/black/box-left.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100, srcSetBreakpoints: [320, 360, 620, 640, 768, 1024, 1200, 1366, 1920]) {
                    ...GatsbyImageSharpFluid
                  }
                }
              },
              boxright: file(relativePath: { eq: "projekty/black/box-right.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100, srcSetBreakpoints: [320, 360, 620, 640, 768, 1024, 1200, 1366, 1920]) {
                    ...GatsbyImageSharpFluid
                  }
                }
              },
              p_lemon: file(relativePath: { eq: "projekty/previews/lemon-resort.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100, srcSetBreakpoints: [320, 360, 620, 640, 768, 1024, 1200, 1366, 1920]) {
                    ...GatsbyImageSharpFluid
                  }
                }
              },
              p_xdeep: file(relativePath: { eq: "projekty/previews/xdeep.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100, srcSetBreakpoints: [320, 360, 620, 640, 768, 1024, 1200, 1366, 1920]) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          `}
          render={data => (
            <div className="page page--project">

              <Banner
                image={data.top.childImageSharp.fluid}
                titleTag="h1"
                title="projects.black.banner" />

              <Scope
                image={data.logo.childImageSharp.fluid}
                title="projects.global.scope"
                logoStyle={logoStyle}
              >
                <ScopeItem title="projects.global.scopeItem1">
                  <IconStyle><Icon8 /></IconStyle>
                </ScopeItem>
                <ScopeItem title="projects.global.scopeItem2">
                  <IconStyle><Icon8 /></IconStyle>
                </ScopeItem>
                <ScopeItem title="projects.global.scopeItem3">
                  <IconStyle><Icon1 /></IconStyle>
                </ScopeItem>
                <ScopeItem title="projects.global.scopeItem6">
                  <IconStyle><Icon5 /></IconStyle>
                </ScopeItem>
                <ScopeItem title="projects.global.scopeItem7">
                  <IconStyle><Icon9 /></IconStyle>
                </ScopeItem>
                <ScopeItem title="projects.global.scopeItem9">
                  <IconStyle><Icon3 /></IconStyle>
                </ScopeItem>
                <ScopeItem title="projects.global.scopeItem11">
                  <IconStyle><Icon10 /></IconStyle>
                </ScopeItem>
              </Scope>

              <Info
                image={data.infobg.childImageSharp.fluid}
                title1="projects.black.info.title1"
                title2="projects.black.info.title2"
                text1="projects.black.info.text1"
                text2="projects.black.info.text2"
              >
                <BardziejButton title="projects.global.online" hrefext="http://www.blackenergydrink.com/" modifier="bardziej-button--white" />
              </Info>

              <Projects
                title="projects.global.projects"
                titleTag="h2"
                bgcStyle={bgcStyle}
                headingStyle={headingStyle}
                headingType="2"
              >
                <div className="section__column">
                  <Img className='image' fluid={data.g1.childImageSharp.fluid} />
                  <Img className='image' fluid={data.g2.childImageSharp.fluid} />
                </div>
                <div className="section__column">
                  <Img className='image' fluid={data.g3.childImageSharp.fluid} />
                  <Img className='image' fluid={data.g4.childImageSharp.fluid} />
                </div>
              </Projects>

              <Movie image={data.moviebg.childImageSharp.fluid} />

              <Desc
                title="projects.global.desc"
                titleTag="h2"
                text="projects.black.desc"
                headingStyle={headingStyle}
                headingType="3"
              />

              <ImageBoxes>
                <ImageBox>
                  <Img className='image' fluid={data.boxleft.childImageSharp.fluid} />
                </ImageBox>
                <ImageBox>
                  <Img className='image' fluid={data.boxright.childImageSharp.fluid} />
                </ImageBox>
              </ImageBoxes>

              <Realizations
                title="projects.global.realizations"
                titleTag="h2"
                headingStyle={headingStyle}
                headingType="4"
              >
                <ImageBoxes>
                  <ImageBox title="projects.balcerzak.realization.title" text="projects.balcerzak.realization.desc">
                    <Img className='image' fluid={data.p_xdeep.childImageSharp.fluid} />
                  </ImageBox>
                  <ImageBox title="projects.balcerzak.realization.title" text="projects.balcerzak.realization.desc">
                    <Img className='image' fluid={data.p_lemon.childImageSharp.fluid} />
                  </ImageBox>
                </ImageBoxes>
              </Realizations>

            </div>
          )}
        />
        <ContactSlide standalone={true} titleTag="h2" />
      </>
    )
  }
};

const customProps = {
  localeKey: 'projects.black',
};

export default withLayout(customProps)(injectIntl(BlackProject));
