import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import Heading from "../../Typography/Heading";
import TextContent from "../../Typography/TextContent";
import {FormattedHTMLMessage} from "react-intl";
import Section from "../../layout/Sections/Section";
import SectionColumn from "../../layout/Sections/SectionColumn";

const HeadingStyle = styled.div`
  ${props => props.headingStyle}
`;

const Desc = ({title, titleTag, text, headingStyle, headingType}) => (
  <Section modifiers={['desc', 'columns', 'padding-x', 'padding-y-equal']} modifiersLayout={['top', 'padding']}>
    <SectionColumn>
      <HeadingStyle headingStyle={headingStyle} type={headingType}>
        <Heading title={title} size="medium-2x" modifiers="" titleTag={titleTag} />
      </HeadingStyle>
    </SectionColumn>
    <SectionColumn>
      <TextContent modifiers={['small']}>
        <FormattedHTMLMessage id={text} />
      </TextContent>
    </SectionColumn>
  </Section>
);

Desc.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default Desc;
