import React from 'react';
import PropTypes from 'prop-types';
import Img from "gatsby-image";
import {FormattedHTMLMessage} from "react-intl";
import Section from "../../layout/Sections/Section";

const Banner = ({image, title, titleTag: Tag}) => (
  <section className="page-banner page-banner--smaller-mobile">
    <Img className='image' fluid={image} />
    <Section modifiers={['padding-x']}>
      <Tag className="page-banner__text">
        <FormattedHTMLMessage id={title} />
      </Tag>
    </Section>
  </section>
);

Banner.defaultProps = {
  titleTag: "div"
};

Banner.propTypes = {
  image: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  titleTag: PropTypes.string
};

export default Banner;
